@import "@alltrails/shared/styles/landsEnd.scss";
.lightboxContent {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
  animation-timing-function: ease-out;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  min-width: 100px;
  pointer-events: auto;

  & > * {
    min-width: 100px;
    height: 100%;
    border-radius: $radius-md;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: brightness(50%) blur(10px);
  filter: brightness(50%) blur(10px);
}

.mainImage {
  object-fit: contain;
  z-index: var(--z-index-default);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
