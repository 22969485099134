@import "@alltrails/shared/styles/landsEnd.scss";
.button {
  @include text-200-bold;
  color: $color-neutral-white;
  height: 48px;
  border-radius: $radius-round;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: 24px;
  background-position: 16px center;
  background-repeat: no-repeat;
  padding: 0 $space-12;
  position: relative;

  width: 100%;
  border: 0;

  background-color: #3c5997;
  &:hover {
    background-color: #5991ea;
  }
}

.icon {
  position: absolute;
  left: var(--space-150);
}
