@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  width: 284px; // Match the calendar popup
}

.buttonContainer {
  position: relative;
  width: 100%;
}

.button {
  @include removeDefaultButtonStyles;
  @include flex-row;
  align-items: center;
  height: 48px;
  width: 100%;
  overflow: hidden;
  padding: var(--space-150) var(--space-200);
  border-radius: var(--radius-md);
  background-color: var(--color-background-primary);
  box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive);
  outline: none;

  &:disabled {
    background-color: var(--color-background-disabled);
    box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-disabled);

    .displayValue,
    .placeholder {
      color: var(--color-text-disabled);
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &.active,
    &:focus-visible {
      box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-interactive-active);
    }

    &.hasError {
      box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-error);
    }
  }
}

.displayValue {
  @include text-200;
  width: calc(
    100% - 24px - var(--space-200)
  ); // 24px is the size of the icon, with an additional space-200 of space in between the value and the icon
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.placeholder {
    color: var(--color-text-secondary);
  }
}

.calendarIcon {
  position: absolute;
  right: var(--space-200);
  top: calc(50% - 24px / 2); // 24px is the size of the icon
}

.clearButton {
  position: absolute;
  right: calc(var(--space-200) - 12px); // The 48px IconButton already has 12px of padding on the right of the actual Icon
  top: 0; // The IconButton is also 48px
}

.popupContainer {
  @include page-width-sm-up {
    width: 284px;
  }
}

.calendarContainer {
  overflow: visible; // Don't cut off the focus styling - the calendar will never be too big for the popup
}
