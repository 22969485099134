@import "@alltrails/shared/styles/landsEnd.scss";
// These values should stay consistent with the ones used in BaseMap styles

.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-200);
  position: absolute;

  &.compact {
    gap: var(--space-100);
  }
}

.bottomCenter,
.bottomLeft,
.bottomRight {
  bottom: 48px; // Leaves space for the attribution and distance scale
  padding-bottom: var(--map-bottom-padding);
}

.bottomCenter {
  left: 50%;
  transform: translateX(-50%);
}

.bottomLeft {
  left: var(--space-300);
}

.bottomRight {
  right: var(--space-300);
}

.topCenter,
.topLeft,
.topRight {
  top: var(--space-300);
}

.topCenter {
  left: 50%;
  transform: translateX(-50%);
}

.topLeft {
  left: var(--space-300);
}

.topRight {
  right: var(--space-300);
}

@include page-width-sm-down {
  .bottomCenter,
  .bottomLeft,
  .bottomRight {
    bottom: 28px; // Leaves space for the attribution and distance scale
  }

  .bottomLeft {
    left: var(--space-200);
  }

  .bottomRight {
    right: var(--space-200);
  }

  .topCenter,
  .topLeft,
  .topRight {
    top: var(--space-200);
  }

  .topLeft {
    left: var(--space-200);
  }

  .topRight {
    right: var(--space-200);
  }
}
