@import "@alltrails/shared/styles/landsEnd.scss";
.linkBlockColumns {
  display: flex;
  gap: var(--space-300);
}

.title {
  margin-top: 0;
  margin-bottom: var(--space-100);
  @include heading-200;

  @include page-width-xs {
    @include text-200;
  }
}

.linksColumn {
  width: calc(50% - var(--space-150));
  @include text-100;
}
