@import "@alltrails/shared/styles/landsEnd.scss";
.reviewContainer {
  padding-bottom: var(--space-200);
  margin-bottom: var(--space-200);
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
}

.newReviewContainer {
  padding-bottom: var(--space-450);
  margin-bottom: 0;
  margin-top: var(--space-450);
}

.hideBorderBottom {
  border-bottom: none;
}
