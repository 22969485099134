@import "@alltrails/shared/styles/landsEnd.scss";
.visitorInfo {
  width: 100%;
  max-width: 1790px;
  margin: 0 auto;
}

.header {
  @include flex-column;
  gap: var(--space-100);

  @include page-width-sm-up {
    margin-bottom: var(--space-450);
  }

  @include page-width-md-up {
    margin-bottom: var(--space-600);
  }

  @include minWidth($breakpoint-lg-size + 0.05) {
    margin-bottom: var(--space-800);
  }

  @include page-width-xs {
    margin-bottom: var(--space-800);
  }

  @include page-width-sm-down {
    gap: var(--space-450);
  }
}

.title {
  @include heading-400;
  margin: 0;

  @include page-width-lg {
    @include heading-600;
  }
}

.container {
  @include flex-column;
  height: 100%;
  gap: var(--space-200);

  > *:not(:last-child) {
    border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  }

  @include page-width-lg {
    height: 220px;
    gap: var(--space-150);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    > *:not(:last-child) {
      border-bottom: none;
    }
  }
}

.botContainer {
  @include flex-column;
  height: 100%;
  gap: var(--space-200);
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.clickableCard.clickableCard,
.mobileClickableCard.mobileClickableCard {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @include removeDefaultButtonStyles;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.card {
  position: relative;
  display: flex;
  padding: var(--space-200);
  width: 100%;
  overflow: hidden;
  background-color: var(--color-background-primary);
  border-radius: var(--radius-lg);
  margin-left: var(--space-150);

  &:hover {
    background-color: var(--color-background-primary-hover);
    box-sizing: border-box;
  }
}

.mobileCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-200);
}

.clickableCard.clickableCard {
  border-left: var(--borders-weight-sm) solid var(--color-border-separator);
  height: 172px;
}

.mobileClickableCard.mobileClickableCard {
  width: 100%;
}

.infoContainer {
  flex: 1;
  @include flex-column;
  justify-content: center;
}

.mobileInfoContainer {
  @include flex-row;
  gap: var(--space-200);
}

.titleContainer {
  display: block;
}

.cardTitle {
  @include heading-400;
  display: inline;
  word-break: break-word;
}

.mobileCardTitle {
  @include heading-300;
  display: inline-block;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include page-width-sm-down {
    @include heading-200;
  }
}

.titleContainer svg {
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
}

.cardDescription {
  display: flex;
  @include text-300;
  color: var(--color-text-secondary);
  height: 30px;
  margin-top: var(--space-50);
}

.mobileCardDescription {
  @include text-300;
  color: var(--color-text-secondary);
  @include page-width-sm-down {
    @include text-200;
  }
}

.iconBackground {
  background-color: var(--color-background-secondary);
  @include flex-row;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: var(--radius-round);
  overflow: hidden;
}

.mobileIconBackground {
  background-color: var(--color-background-secondary);
  @include flex-row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: var(--radius-round);
  overflow: hidden;
  flex-shrink: 0;
}

.content {
  @include flex-column;
  gap: var(--space-600);
  text-align: left;
}

.mobileContent {
  @include flex-column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.iconContainer {
  @include flex-row;
  gap: var(--space-200);
}
