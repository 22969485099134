@import "@alltrails/shared/styles/landsEnd.scss";
.popper.popper {
  background-color: var(--color-background-inverse);
  border: none;
  @include text-100;
  color: var(--color-text-primary-inverse);
  padding: var(--space-50) var(--space-100);
  border-radius: var(--radius-sm);
  word-wrap: break-word;
  min-width: 30px;
  z-index: var(--z-index-popover);
}
