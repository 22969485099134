@import "@alltrails/shared/styles/landsEnd.scss";
.popover.popover,
.bottomSheet.bottomSheet {
  @include flex-column;
  padding: 0;
}

.popover {
  .headerAndContent {
    padding: var(--space-150) var(--space-150) 0;
  }

  .header {
    margin-bottom: var(--space-100);
  }

  .content {
    padding-bottom: var(--space-150);
  }

  .buttons {
    padding: var(--space-50) var(--space-150);
  }
}

.headerAndContent {
  @include flex-column;
  flex: 1;
  overflow: hidden;
  padding: 0 var(--space-300);
}

.header {
  @include flex-row;
  align-items: center;
  gap: var(--space-100);
  margin-bottom: var(--space-200);

  svg,
  .closeButton {
    flex-shrink: 0;
  }
}

.title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  overflow-y: auto;
  padding-bottom: var(--space-300);
}

.buttons {
  @include flex-row;
  justify-content: flex-end;
  gap: var(--space-100);
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);
  padding: var(--space-200) var(--space-300);
}
