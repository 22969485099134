@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  justify-content: center;

  @include page-width-sm-down {
    @include flex-column;
  }
}

.content {
  width: 100%;
}

.title {
  margin: 0 0 var(--space-450) 0;

  @include page-width-md-up {
    margin: 0 0 var(--space-600) 0;
  }
}

.desktopLinkSection {
  display: block;
  width: calc(33% - var(--space-200));
}

.linkSection {
  display: block;

  @include page-width-md-up {
    display: flex;
    gap: var(--space-300);
  }
}

.linksPadding {
  padding-bottom: 112px;

  @include page-width-sm-down {
    padding-bottom: var(--space-600);
  }
}
