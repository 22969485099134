@import "@alltrails/shared/styles/landsEnd.scss";
.progressBar {
  @include flex-row;
  gap: var(--space-4);
}

.stepContainer {
  flex: 1;
  display: inline-flex;
  align-items: center;
}

button.stepContainer {
  @include removeDefaultButtonStyles;
  height: 48px; // Expanded click target
  cursor: pointer;

  &:hover .step {
    box-shadow: var(--shadow-100);
  }
}

.step {
  position: relative;
  flex: 1;
}

.stepEmpty,
.stepProgress {
  height: 3px;
  border-radius: var(--radius-round);
  background-color: var(--color-text-primary);
  opacity: 0.2;
}

.stepEmpty {
  width: 100%;
}

.stepProgress {
  position: absolute;
  top: 0;
  left: 0;

  &.complete {
    opacity: 1;
    width: 100%;

    &.animating {
      width: 0%;
      animation: completion var(--animation-duration) linear forwards;
    }
  }
}

@keyframes completion {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
