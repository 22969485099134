@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  background: var(--color-background-accent-strong);
  padding: var(--space-800) var(--space-200);

  @include small-down {
    // Adds additional padding to make room for fixed bottom CTA
    padding-bottom: 115px;
  }
}

.userLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--space-100);
  @include text-100;
  color: var(--color-text-primary-inverse);
  margin-top: var(--space-200);

  a {
    color: var(--color-text-primary-inverse);
    &:hover {
      color: var(--color-text-primary-inverse);
      text-decoration: underline;
    }
  }

  a:first-child {
    margin-left: 0px;
  }
}

.innerContainer {
  max-width: 1240px;
  margin: 0 auto;
}

.wideInnerContainer {
  max-width: 100%;
}

.alltrailsLogo {
  width: 140px;
  height: auto;
}

.nav {
  margin: var(--space-600) 0px var(--space-300);
}

.bullet {
  display: none;
}

.copyright {
  @include text-100-bold;
  color: var(--color-text-primary-inverse);
}

.membersContainer,
.appForOutdoors,
.connectWithUs {
  margin-bottom: var(--space-600);
}

.bottomText {
  @include flex-column;
  padding-top: var(--space-200);
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);
  flex-direction: column-reverse;
}

.expandableContainer {
  @include flex-column;
  margin-bottom: var(--space-600);
}

.footerLinksContainer {
  display: none;
}

.languageSelect {
  margin: 0px 0px var(--space-200);
  white-space: nowrap;
  display: flex;
}

.footNotes {
  @include flex-column;
  @include text-100;
}

.panelsContainer {
  gap: var(--space-300);
}

@include minWidth($breakpoint-768) {
  .container {
    padding: var(--space-800) var(--space-200) var(--space-300);
  }

  .bullet {
    display: block;
    margin: 0 var(--space-100);
  }

  .expandableContainer {
    display: none;
  }

  .membersContainer {
    width: auto;
    margin-bottom: var(--space-300);
  }

  .appForOutdoors {
    width: auto;
    margin-bottom: var(--space-300);
  }

  .connectWithUs {
    margin-bottom: var(--space-300);
  }

  .footNotes {
    @include flex-row;
    align-items: center;
    margin-right: auto;
  }

  .footerLinksContainer {
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .panelsContainer {
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .nav {
    margin-bottom: var(--space-600);
  }

  .bottomText {
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .languageSelect {
    margin-bottom: 0px;
    text-align: right;
  }
}

.footNotes {
  color: var(--color-text-primary-inverse);
}

@include minWidth($breakpoint-1024) {
  .panelSpacer {
    display: block;
  }

  .appForOutdoors {
    min-width: 25%;
  }
}

@include minWidth($breakpoint-1240) {
  .wideContainer {
    padding: var(--space-800) 81px;
  }
}

@include minWidth($breakpoint-1400) {
  .container {
    padding: var(--space-800) 0px var(--space-300);
  }

  .wideContainer {
    padding: var(--space-800) 81px;
  }
}
