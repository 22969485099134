@import "@alltrails/shared/styles/landsEnd.scss";
.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-200);
}

.apple,
.google {
  @include text-200-bold;
  color: $color-neutral-white;
  height: 48px;
  border-radius: $radius-round;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: 24px;
  background-position: 16px center;
  background-repeat: no-repeat;
  width: 100%;
  border: 0;
  padding: 0 $space-12;
  position: relative;
}

.google {
  background-color: #4184f3;
  &:hover {
    background-color: #6497ef;
  }
}

.apple {
  background-color: #000;
  &:hover {
    background-color: #303030;
  }
}

.text {
  flex: 1;
}

.icon {
  position: absolute;
  left: var(--space-150);
}
