@import "@alltrails/shared/styles/landsEnd.scss";
.labelAndSelect {
  background: var(--color-background-primary);
  margin-top: var(--space-300);
  @include flex-row;
  align-items: center;
  @include body-3;

  .label {
    margin-right: var(--space-50);
  }
}

.select {
  border: none;
  color: var(--color-brand-primary);
  cursor: pointer;
  text-align: right;
  width: auto;
  margin-left: var(--space-50);
  &:hover {
    color: var(--color-brand-primary);
  }
}

.sticky {
  position: sticky;
  top: 0;
  margin-top: 0;
  z-index: var(--z-index-sticky);
}
