@import "@alltrails/shared/styles/landsEnd.scss";
.selectContainer {
  position: relative;
}

.chevron {
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.md {
    right: var(--space-150); // Matches value in padding-right calculation for the select
  }

  &.sm {
    right: var(--space-100); // Matches value in padding-right calculation for the select
  }
}

.select {
  appearance: none; // Hide the default select arrow
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  background: var(--color-background-primary);
  border-radius: var(--radius-md);
  border: none;
  // Use box-shadow instead of border to avoid the text jumping when the border width changes on focus
  box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive);

  &.md {
    @include text-200;
    height: 48px;
    padding-left: var(--space-200);
    padding-right: calc(2 * var(--space-150) + 24px); // Leave space for 24px chevron icon
  }

  &.sm {
    @include text-100;
    height: 36px;
    padding-left: var(--space-150);
    padding-right: calc(2 * $space-8 + 20px); // Leave space for 20px chevron icon
  }

  &:focus-visible {
    outline: none;
  }

  // The placeholderColor mixin does not work for select elements
  &.placeholder {
    color: var(--color-text-placeholder);
  }

  &:disabled {
    background-color: var(--color-background-disabled);
    box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-disabled);
    color: var(--color-text-disabled);

    // The placeholderColor mixin does not work for select elements
    &.placeholder {
      color: var(--color-text-disabled);
    }
  }

  &:not(:focus-visible):not(:disabled):not(.hasError) {
    @include hover {
      box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive-hover);
    }
  }

  &.hasError:not(:disabled) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-error);
  }

  &:focus-visible:not(.hasError) {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-interactive-active);
  }
}
