@import "@alltrails/shared/styles/landsEnd.scss";
.span {
  @include text-100;
  @include flex-row;
  justify-content: center;
  padding: 0 var(--space-50);
  min-width: 20px;
  font-weight: 700;
  border-radius: var(--radius-round);
}

.default {
  background: var(--color-background-secondary);
}

.accent {
  background: var(--color-ui-highlight);
}

.inverse {
  background: var(--color-background-inverse);
  color: var(--color-text-primary-inverse);
}
