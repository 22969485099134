@import "@alltrails/shared/styles/landsEnd.scss";
.formInputContainer {
  @include flex-column;
  gap: $space-24;

  // This allows us to apply the error styles to the Recurly credit card input ourselves.
  // Recurly does this automatically when the input is changed, but we also want to apply these styles when the form is submitted with an empty input.
  :global(.recurly-card-element-container) {
    &:global(.invalid) {
      :global(.recurly-element) {
        &::after {
          border: var(--borders-weight-md) solid var(--color-border-error);
        }
      }
    }
  }

  :global(.recurly-element) {
    position: relative;
    @include text-200;
    padding: var(--space-4) var(--space-16);
    height: 43px;
    z-index: var(--z-index-default);

    // Prevents content shifting when updating border width.
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // prevents the after element from existing on top of .recurly-element which impacts focus behavior.
      z-index: -1;
      border: var(--borders-weight-sm) solid var(--color-border-interactive);
      border-radius: var(--radius-md);
    }

    &:hover {
      &::after {
        border: var(--borders-weight-sm) solid var(--color-border-interactive-active);
      }
    }

    &:global(.recurly-element-invalid):hover {
      &::after {
        border: var(--borders-weight-md) solid var(--color-border-error);
      }
    }

    &:global(.recurly-element-focus):hover {
      &::after {
        border: var(--borders-weight-md) solid var(--color-border-interactive-active);
      }
    }
  }

  :global(.recurly-element-focus) {
    &::after {
      border: var(--borders-weight-md) solid var(--color-border-interactive-active);
    }
  }

  :global(.recurly-element-invalid) {
    &::after {
      border: var(--borders-weight-md) solid var(--color-border-error);
    }
  }
}

.formInput {
  width: 100%;
  font-size: $font-size-200;
  color: $color-text-primary;
  font-family: $font-family-primary;

  &.invalidInput {
    height: auto;
    margin-bottom: 0;
  }
}

.namesContainer {
  display: flex;
  gap: $space-16;
  justify-content: space-between;

  & > input {
    flex: 1;
  }

  @include small-down {
    flex-direction: column;
    gap: $space-24;
  }
}

.couponCol {
  align-items: center;
  display: flex;
  width: 100%;
}

.couponButton {
  background: none;
  border: none;
  text-decoration: underline;
}

.country {
  margin-top: $space-24;
}

.errorMessage {
  @include text-100;
  margin-top: $space-4;
  color: $color-text-error;
}
