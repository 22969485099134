@import "@alltrails/shared/styles/landsEnd.scss";
.pin {
  position: relative;
  text-align: center;
  bottom: -13px;
  transition: transform 0.3s ease-in-out;
}

.pinImage {
  width: 42px; // half of the image width, as we are rendering icons at 1x
}
