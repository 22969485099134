@import "@alltrails/shared/styles/landsEnd.scss";
.counterBadge {
  position: absolute;
  top: 21px;
  left: 23px;
  border: var(--borders-weight-md) solid var(--color-background-primary);
  min-width: 20px;
  height: 20px;
  align-items: center;
  line-height: normal;
  pointer-events: none;
}
