@import "@alltrails/shared/styles/landsEnd.scss";
.titleAndStatsContainer {
  @include flex-column;
  gap: var(--space-100);
  margin-bottom: var(--space-300);

  @include maxWidth($breakpoint-sm-size) {
    margin: 0 var(--space-300) var(--space-300);
  }
}

.dividerDot {
  @include text-100;
  vertical-align: middle;
  color: var(--color-text-secondary);
  margin: 0 var(--space-50);
}

.title {
  @include heading-600;
  margin: 0;

  @include page-width-md-down {
    @include heading-400;
  }
}

.stats {
  @include flex-row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.star {
  flex-shrink: 0;
}

.statsContainer {
  margin: 0;
  @include flex-row;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-50);
}

.statsText {
  margin: 0;
  @include flex-row;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  @include text-200;
  gap: var(--space-50);

  @include maxWidth($breakpoint-sm-size) {
    @include text-100;
  }
}

.hasAlertsStats {
  @include text-100;
}

.link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  margin-left: var(--space-50);
}

.country {
  margin-left: 0;
  flex-shrink: 0;

  @include maxWidth($breakpoint-sm-size) {
    display: none;
  }
}

.countrySpace {
  @include maxWidth($breakpoint-sm-size) {
    display: none;
  }
}
