@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  width: 100%;
}

.title {
  @include text-300-bold;
  margin-bottom: var(--space-100);
  white-space: nowrap;
  color: var(--color-text-primary-inverse);
}

.membersIconsContainer {
  @include flex-row;
  white-space: wrap;
  flex-wrap: wrap;
}

.logo {
  margin-right: var(--space-300);
  margin-bottom: var(--space-150);
}

@include minWidth($breakpoint-480) {
  .logo {
    margin-right: $space-32;
    margin-bottom: var(--space-100);
  }
}

@include minWidth($breakpoint-768) {
  .title {
    margin-bottom: var(--space-300);
  }
}

@include minWidth($breakpoint-1024) {
  .membersIconsContainer {
    white-space: nowrap;
  }
}
