@import "@alltrails/shared/styles/landsEnd.scss";
.container,
.modalContainer,
.wrapper,
.section,
.dayHourContainer {
  @include flex-column;
}

.modalContainer {
  gap: var(--space-300);
}

.accordionContainer {
  padding: calc(var(--space-200) - var(--borders-weight-lg)) 0px;
}

.section {
  gap: var(--space-50);
}

.header {
  @include heading-200;
}

.sectionHeader {
  @include heading-300;
  padding: var(--space-300) 0 var(--space-150) 0;
}

.title,
.description,
.dayHour {
  @include text-200;
}

.description {
  p {
    margin: 0;
  }
  p:first-child {
    margin-bottom: var(--space-200);
  }
  margin-bottom: var(--space-300);
}

.divider {
  border: var(--borders-weight-sm) solid var(--color-border-separator);
  margin-bottom: var(--space-300);
}

.externalButton {
  padding-bottom: var(--space-200);
}

.content {
  padding-bottom: var(--space-200);
}

.dayHourContainer {
  padding: var(--space-200) 0 var(--space-300) 0;
}

.dayHourRow {
  @include flex-row;
  justify-content: space-between;
  padding-top: var(--space-50);
}
