@import "@alltrails/shared/styles/landsEnd.scss";
.inputItem {
  // https://app.zeplin.io/project/60ca807a5c0b90b9a32b4af1/screen/60ca8eaabec4ebbd1fd85301
  margin-bottom: $space-16;
}

.serverError {
  @include text-200;
  color: $color-text-error;
  line-height: $space-24;
  margin-bottom: $space-24;
  text-align: center;
}
