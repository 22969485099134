@import "@alltrails/shared/styles/landsEnd.scss";
.overlay {
  position: fixed;
  z-index: 1004; // Modals use 1003
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--space-200);
  background-color: var(--color-scrim-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  position: relative;
  @include flex-column;
  gap: var(--space-300);
  padding: var(--space-300);
  background-color: var(--color-background-primary);
  border-radius: var(--radius-lg);
  max-width: 100%;
  box-shadow: var(--shadow-300);

  svg {
    align-self: center;
  }

  &.sm {
    width: 360px;

    .title {
      @include heading-300;
    }
  }

  &.md {
    width: 480px;
  }

  &.lg {
    width: 640px;
  }

  &.sm,
  &.md,
  &.lg {
    @include maxWidth($breakpoint-640) {
      width: 320px;

      .title {
        @include heading-300;
      }
    }
  }
}

.iconAndText {
  @include flex-column;
  justify-content: center;
  align-items: center;
  gap: var(--space-300);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iconBackground {
    border-radius: var(--radius-round);
    background-color: var(--color-neutral-100-a);
    padding: var(--space-150);
  }

  .text {
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: var(--space-100);
  }

  .title {
    @include heading-400;
    text-align: center;
  }

  .body {
    @include text-200;
    color: var(--color-text-secondary);
    text-align: center;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-100);
}
